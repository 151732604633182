
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <form @submit.prevent="addPackedItems" autocomplete="off">
                    <v-layout row wrap>
        
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="order_items" v-model="packed_items.order_item_id" dense  filled outlined  item-text="order_item_id"
                                    item-value="order_item_id" :return-object="false" :label="$store.getters.language.data.order_items.order_item_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="packed_orders" v-model="packed_items.packed_order_id" dense  filled outlined  item-text="packed_order_id"
                                    item-value="packed_order_id" :return-object="false" :label="$store.getters.language.data.packed_orders.packed_order_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.packed_items.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"  show-select v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="packed_item_id">
                    <template v-slot:[`item.packed_item_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/packed_items-list/'+item.packed_item_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectPackedItems(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deletePackedItemsList">{{$store.getters.language.data.packed_items.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.packed_items.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.packed_items.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deletePackedItems(selected_packed_items.packed_item_id)">
                {{$store.getters.language.data.packed_items.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/packed_items.request.js'
    export default {
        data() {
            return {
                packed_items: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_packed_items : {},
                delete_dialog: false,
                headers: [

                    
                    {
                        text: this.$store.getters.language.data.order_items.order_item_id,
                        align: 'start',
                        sortable: true,
                        value: 'order_item_id',
                    },
                    {
                        text: this.$store.getters.language.data.packed_orders.packed_order_id,
                        align: 'start',
                        sortable: true,
                        value: 'packed_order_id',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'packed_item_id',
        }
                ],
            }
        },
        computed: {
            order_items(){
                return this.$store.getters.order_items_list
            },
            packed_orders(){
                return this.$store.getters.packed_orders_list
            },
        },
        mounted(){
            this.readPackedItems();
        },
        methods: {
            addPackedItems() {
                this.loading_btn = true
                requests.createPackedItems(this.packed_items).then(r => {
                    if (r.status == 200) {
                        this.packed_items = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'PackedItems Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add PackedItems',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deletePackedItems(packed_item_id) {
                requests.deletePackedItems(packed_item_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.packed_item_id != packed_item_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'PackedItems Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deletePackedItemsList() {
                let ids = this.selected_rows.map(m => m.packed_item_id)
                requests.deletePackedItemsList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.packed_item_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' PackedItems Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readPackedItems() {
                this.loading = true
                requests.getAllPackedItems().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read PackedItems',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read PackedItems',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectPackedItems(i) {
                this.selected_packed_items = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    